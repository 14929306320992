<template>
  <div ref="glideEl" class="glide">
    <div class="glide__track" data-glide-el="track">
      <ul class="glide__slides">
        <li v-for="(image, imageIndex) in filteredImages" :key="imageIndex" class="glide__slide">
          <NuxtLink :to="rvLink" :target="linkTarget" @click.prevent="listingClicked" class="img-link">
            <!-- Look into using picture tag -->
            <img
              :src="`${image}?width=600&height=400`"
              alt=""
              width="450"
              height="300"
              :loading="imageIndex === 0 && eagerLoading ? 'eager' : 'lazy'"
            />
          </NuxtLink>
        </li>
      </ul>
    </div>

    <div v-if="isLargeScreen && filteredImages.length > 1" class="glide__arrows" data-glide-el="controls">
      <button
        class="glide__arrow glide__arrow--left"
        data-glide-dir="<"
        @click.once="mountOnClickAndGoTo(images.length - 1)"
      >
        <IconChevron direction="left" />
      </button>
      <button class="glide__arrow glide__arrow--right" data-glide-dir=">" @click.once="mountOnClickAndGoTo(1)">
        <IconChevron direction="right" />
      </button>
    </div>

    <div v-if="filteredImages.length > 1" class="glide__bullets" data-glide-el="controls[nav]">
      <button
        v-for="(_, index) in images"
        :key="index"
        :class="['glide__bullet', { 'glide__bullet--active': index === 0 }]"
        :data-glide-dir="`=${index}`"
        @click.once="mountOnClickAndGoTo(index)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useElementVisibility, watchOnce } from '@vueuse/core'
// @ts-ignore
import Glide, { Controls, Swipe } from '@glidejs/glide/dist/glide.modular.esm'
import { trackListingClicked } from '~/lib/events'
import type { Rv } from '~/types/api'

const isLargeScreen = useIsLargeScreen()

const $config = useRuntimeConfig()
const glideEl = ref<HTMLElement | null>(null)
let glide: Glide

const props = withDefaults(
  defineProps<{
    rv: Rv
    images: string[]
    eagerLoading?: boolean
    heroImageIndex?: number
    maxImages?: number
    cta?: string
  }>(),
  {
    eagerLoading: false,
    heroImageIndex: 0
  }
)

const getLinkTarget = (_: any, isDefault: boolean) => {
  return !isDefault && isLargeScreen.value ? '_blank' : undefined
}

const linkTarget = ref(getLinkTarget(null, true))

useOnHydration({ ref: linkTarget, watchRef: isLargeScreen, callback: getLinkTarget })

const rvLink = computed(() => {
  return getLocalePath({
    name: 'rv-slug',
    params: { slug: props.rv.id },
    query: { cta: !isBot() ? props.cta : undefined }
  })
})

const filteredImages = computed(() => {
  if (props.maxImages == null) return props.images
  return props.images.slice(0, props.maxImages)
})

if (!isLargeScreen.value) {
  const targetIsVisible = useElementVisibility(glideEl)
  watchOnce(targetIsVisible, () => {
    if (glide) return
    glide = new Glide(glideEl.value, { type: 'carousel' }).mount({ Swipe, Controls })
  })
}

const mountOnClickAndGoTo = (slideIndex: number) => {
  if (glide) return
  glide = new Glide(glideEl.value, { type: 'carousel' }).mount({ Controls }).go(`=${slideIndex}`)
}

const listingClicked = async ({ currentTarget: target }: { currentTarget: HTMLAnchorElement }) => {
  trackListingClicked({
    rv: props.rv,
    cta: props.cta
  })

  //We had to add this because the target was not updating due some issue on vue hydration.
  //We'll need to remove this once the issue is fixed.
  target.target = linkTarget.value ?? ''
  await windowNavigateTo(target)
}

onBeforeUnmount(() => {
  glide?.destroy()
})
</script>

<style lang="scss" scoped>
@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';

.img-link {
  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    vertical-align: bottom;
    background: #000;

    @media (min-width: 768px) {
      height: 250px;
    }

    @media (min-width: 1024px) {
      height: 300px;
    }
  }
}
.glide {
  &__track {
    border-radius: 1rem 1rem 0 0;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
    opacity: 0;
    background-color: rgba(255, 255, 255, 0.8);
    color: $primary-500;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    transition: 0.4s;

    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
      outline: none;
    }

    svg {
      width: 1rem;
      height: 1rem;
      vertical-align: middle;
    }

    &--left {
      left: 10%;
    }

    &--right {
      right: 10%;
    }

    &--disabled {
      opacity: 0.33;
    }
  }

  &__bullets {
    position: absolute;
    z-index: 2;
    left: 50%;
    display: inline-flex;
    list-style: none;
    transform: translateX(-50%);

    --bullet-size: 8px;
    margin: 0;
    width: calc((var(--bullet-size) * 5) + (var(--bullet-size) * 4));
    height: var(--bullet-size);
    bottom: 10px;
  }

  &__bullet {
    height: var(--bullet-size);
    width: var(--bullet-size);
    display: none;
    transform: scale(0);
    position: absolute;
    margin: 0;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 0;
    border-radius: 50%;
    border: 2px solid transparent;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    line-height: 0;
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);

    &:focus {
      outline: none;
    }

    &--active {
      background-color: white;
      transform: scale(1);
      opacity: 1;
      display: block;
      left: calc(50% - (var(--bullet-size) / 2));
    }
  }

  &__bullet:has(+ .glide__bullet--active),
  &__bullet--active + &__bullet,
  &__bullet:has(+ .glide__bullet + .glide__bullet--active),
  &__bullet--active + &__bullet + &__bullet {
    display: block;
  }

  &__bullet:has(+ .glide__bullet--active),
  &__bullet--active + &__bullet {
    transform: scale(0.66);
  }

  &__bullet:has(+ .glide__bullet + .glide__bullet--active),
  &__bullet--active + &__bullet + &__bullet {
    transform: scale(0.33);
  }

  &__bullet:has(+ .glide__bullet + .glide__bullet--active) {
    left: calc(0% - (var(--bullet-size) / 2));
  }

  &__bullet:has(+ .glide__bullet--active) {
    left: calc(25% - (var(--bullet-size) / 2));
  }

  &__bullet--active + &__bullet {
    left: calc(75% - (var(--bullet-size) / 2));
  }

  &__bullet--active + &__bullet + &__bullet {
    left: calc(100% - (var(--bullet-size) / 2));
  }

  &:hover {
    .glide__arrow {
      opacity: 1;

      &--left {
        left: 2%;
      }

      &--right {
        right: 2%;
      }
    }
  }
}
</style>
