<i18n lang="yaml">
en:
  away: '{distance} away'
  sleeps: Sleeps
fr:
  away: 'à {distance}'
  sleeps: Couche
</i18n>

<i18n lang="yaml" src="~/i18n/rv-type.yml"></i18n>

<template>
  <div v-show="!loading" class="rv-card bg-white rounded-2xl">
    <RvImageCarousel
      v-if="!loading"
      :key="rv.id"
      :rv="rv"
      :images="images"
      :eager-loading="eagerLoading"
      :max-images="maxImages"
      :cta="cta"
      @click="carouselClick($event)"
    />

    <NuxtLink
      :to="linkRv"
      :target="linkTarget"
      @click.prevent="listingClicked"
      :title="rv.name"
      :data-testid="`rv-card-${rv.id}`"
      class="rv-info p-4"
    >
      <span class="rv-name">
        <span>{{ rv.name }}</span>
      </span>

      <template v-if="rv.sleepingCapacity">{{ t('sleeps') }} {{ rv.sleepingCapacity }} &bull; </template
      >{{ t(`RVType.${rv.class}`) }}<br />
      <template v-if="rv.location.city && rv.location.regionCode"
        >{{ rv.location.city }}, {{ rv.location.regionCode }}</template
      >
      <template v-if="showDistanceAway"> ({{ t('away', { distance: distanceAway }) }})</template>

      <span class="price-block">
        <RvPrice :rv="rv" />
      </span>
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
import { trackListingClicked } from '~/lib/events'
import { AppProviderKeys } from '~/providers'
import type { Rv } from '~/types/api'
import type { IPSearchParameters } from '~/types/provider/search-parameters'

const sp = inject<IPSearchParameters | null>(AppProviderKeys.SearchParameters, null)
const { t } = useI18n({ useScope: 'local' })
const isLargeScreen = useIsLargeScreen()
const $config = useRuntimeConfig()

const linkTarget = computed(() => {
  return isLargeScreen.value ? '_blank' : undefined
})

const props = withDefaults(
  defineProps<{
    rv: Rv
    index?: number
    loading?: boolean
    requestId?: string
    cta?: string
    maxImages?: number
    pageSource: string
  }>(),
  {
    loading: false
  }
)

const eagerLoading = computed((): boolean => {
  if (props.index == null) return false
  if (!isLargeScreen.value) return props.index === 0
  return props.index < 3
})

const images = computed(() => {
  return getRvImages(props.rv)
})

const distanceAway = computed((): string | undefined => {
  if (sp == null || !props.rv.distance) return

  return useDistance().distanceWithUnits(props.rv.distance, props.rv.location.countryCode) as string
})

const linkRv = computed(() => {
  return getLocalePath({
    name: 'rv-slug',
    params: { slug: props.rv.id },
    query: {
      cta: !isBot() ? props.cta : undefined
    }
  })
})

const showDistanceAway = computed((): boolean => {
  return props.cta !== 'map' && distanceAway.value != null
})

const listingClicked = async ({ currentTarget: target }: { currentTarget: HTMLAnchorElement }) => {
  trackListingClicked({
    rv: props.rv,
    cta: props.cta
  })

  //We had to add this because the target was not updating due some issue on vue hydration.
  //We'll need to remove this once the issue is fixed.
  target.target = linkTarget.value ?? ''
  await windowNavigateTo(target)
}

function carouselClick(e: MouseEvent) {
  const target = e.target as Element | undefined
  if (target?.closest('.glide__arrow') || target?.closest('.glide__bullet')) {
    e.stopPropagation()
    e.preventDefault()
  }
}
</script>

<style lang="scss" scoped>
.loading {
  svg {
    display: block;
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;

    rect {
      fill: $primary-100;
    }
  }

  span {
    display: block;
    height: 0.75rem;
    margin-top: 0.25rem;
    background: $primary-100;
    width: 60%;

    &.placeholder-name {
      width: 80%;
      height: 1.25rem;
      margin-bottom: 0.75rem;
    }

    &.placeholder-price {
      width: 40%;
      height: 1.5rem;
      margin-top: 1rem;
    }
  }
}

.rv-card {
  min-width: 0;

  .rv-info {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #567390;
    padding-top: 1rem;

    .rv-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      grid-gap: 0.5rem;
      color: $primary-500;
      font-weight: 600;
      font-style: normal;
      font-size: 1rem;
      line-height: 1.5rem;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 0.25rem;

      *:first-child {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .rating {
        display: flex;
        align-items: center;

        svg {
          width: 1.125em;
          margin-right: 0.25rem;
        }
      }
    }

    .price-block {
      line-height: 1.5rem;
      font-weight: 600;
      font-size: 1.125rem;
      color: $primary-500;
      margin-top: 0.75rem;

      :deep(.msrp) {
        font-size: 0.5rem;
        line-height: 0.5rem;
      }
    }

    .badge {
      font-weight: 600;
      font-style: normal;
      font-size: 0.875rem;
      line-height: 1.25rem;
      align-self: flex-start;
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
    }
  }
}
</style>
