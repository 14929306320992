import type { Rv } from '~/types/api'

export default (rv: Rv) => {
  if (rv.images != null && rv.images.length > 0) return rv.images

  const $config = useRuntimeConfig()

  const placeholder = rv.category === 'Towable' ? 'trailer' : 'motorhome'
  return [`${$config.public.app.cdnEndpoint}/images/placeholder/${placeholder}.png`]
}
