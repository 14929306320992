import type { Ref } from 'vue'

type props = { ref: Ref<any>; watchRef: Ref<any>; callback: (...v: any) => any }
export default ({ ref, watchRef, callback }: props) => {
  //This code make sure that a variable is updated on hydration
  //This tries to fix a limitation on computed properties that are not updated on hydration
  onMounted(() => {
    ref.value = callback()
  })
  watch(
    () => watchRef.value,
    (v) => {
      ref.value = callback(v)
    }
  )
}
