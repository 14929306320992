<i18n lang="yaml">
en:
  noPriceCta: Contact seller
fr:
  noPriceCta: Contacter le vendeur
</i18n>

<template>
  <div v-if="props.rv.msrpPrice" class="mr-3">
    <div class="line-through">
      {{
        useCurrency().getValueWithCurrency({
          amount: props.rv.msrpPrice,
          country: props.rv.location.countryCode
        })
      }}
    </div>
  </div>
  <div v-if="props.rv.listPrice" class="mr-3 text-xl font-bold">
    {{
      useCurrency().getValueWithCurrency({
        amount: props.rv.listPrice,
        country: props.rv.location.countryCode
      })
    }}<sup>*</sup>
  </div>
  <span v-if="!props.rv.msrpPrice && !props.rv.listPrice" class="no-price">{{ t('noPriceCta') }}</span>
</template>

<script lang="ts" setup>
import type { Rv } from '~/types/api'

const props = defineProps<{
  rv: Rv
}>()

const { t } = useI18n({ useScope: 'local' })
</script>
