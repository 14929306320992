type DistanceUnit = 'kilometer' | 'mile'

export const useDistance = () => {
  const distanceWithUnits = (distanceInKm?: number, country?: string): string | number | undefined => {
    if (!distanceInKm) return undefined

    const unit = ({ US: 'mile', CA: 'kilometer' }[country ?? 'CA'] ?? 'kilometer') as DistanceUnit

    try {
      const locale = useI18n().locale

      return new Intl.NumberFormat(locale.value, {
        style: 'unit',
        maximumFractionDigits: 1,
        minimumFractionDigits: 1,
        unit,
        unitDisplay: 'short'
      }).format(distanceInKm * (unit === 'mile' ? 0.62137 : 1))
    } catch {
      // old browser
      return `${distanceInKm.toFixed(1)} ${{ kilometer: 'km', mile: 'mi' }[unit as DistanceUnit] ?? ''}`.trim()
    }
  }

  return {
    distanceWithUnits
  }
}
